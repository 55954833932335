import React from "react"
import { graphql, Link, navigate } from "gatsby"
import Seo from "../components/Seo/Seo"
import Container from "../components/Container/Container"
import G from "../images/groenhout_g.png"
import SmallHero from "../components/SmallHero/SmallHero"
import ImageGallery from "../components/WoningComponent/ImageGallery/ImageGallery"
import WoningInfo from "../components/WoningComponent/WoningInfo/WoningInfo"
import WoningText from "../components/WoningComponent/WoningText/WoningText"
import WoningContact from "../components/WoningComponent/WoningContact/WoningContact"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowsAltH, faMapMarkerAlt, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"

const SingleWoningBinnenkortTemplate = ({ data }) => {

  if(!data) return null

  const w = data.wpBinnenkortInDeVerkoop

  const bgImg = w.binnenkortVerkoop.hoofdfoto?.mediaItemUrl !== null ? data.wpBinnenkortInDeVerkoop.binnenkortVerkoop.hoofdfoto?.mediaItemUrl : null
  const title = w.title

  // const koopConditie = w.binnenkortVerkoop.koopconditie
  // const price = `${new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(parseInt(w.binnenkortVerkoop.koopprijs))} ${koopConditie}`
  const woonoppervlak = w.binnenkortVerkoop.woonoppervlakte !== null ? <><FontAwesomeIcon icon={faArrowsAltH} className="mr-2" /> <span>{w.binnenkortVerkoop.woonoppervlakte}m²</span></> : null
  const wijk = w.binnenkortVerkoop.plaats !== null ?<><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /><span>{w.binnenkortVerkoop.plaats}</span></> : null

  const subtitle = [
    wijk,
    woonoppervlak
  ]

  const seo = data.wpBinnenkortInDeVerkoop.seo
  const seller = data.allWpVerkopendeMakelaar
  const breadcrumb = seo.breadcrumbs.map((e, i) => (
    <> 
    <span key={i}>
      <Link to={e.url} className="mr-2 text-gray"><span dangerouslySetInnerHTML={{__html: e.text}}></span></Link> 
      {(i < seo.breadcrumbs.length - 1) ? 
      <FontAwesomeIcon icon={faChevronRight} className="mr-2 text-gray" />
        : null
      }
    </span>
    </>
  ))

//   // First Image of House
  const mainImg =  data.wpBinnenkortInDeVerkoop.binnenkortVerkoop.hoofdfoto?.mediaItemUrl !== null ? data.wpBinnenkortInDeVerkoop.binnenkortVerkoop.hoofdfoto?.mediaItemUrl : null
  const images = null;
  
//   // const bgImg =  data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.localFile.publicURL : null
//   const bgImg = data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.mediaItemUrl : null
//   const images =  data.wpWoning.woningen.woningenImages !== null ? data.wpWoning.woningen.woningenImages.filter((e) => {
//     if(e.title.includes('Plattegrond') || e.title.includes('Hoofdfoto') || e.title.includes('Document')){
//       console.log(e.title)
//       return false;
//     }
//      else{
//       return true;
//      }
//     }) : null

const scrollHandler = () => {
    navigate('/contact')
}


  return(
    <>
      <Seo title="Binnenkort in de verkoop | Groenhout Makelaars"></Seo>
      <SmallHeroNew title={title} subtitle={subtitle} breadcrumbs={undefined} desc="" overlay={true} overlayImage={G} image={bgImg !== undefined ? bgImg : ''}></SmallHeroNew>
      <Container styles={'my-16'}>
        <div className="flex flex-row items-center mb-12">
          <div className="w-1/12">
            <Link to="/aanbod/binnenkort"><span className="text-light bg-red hover:bg-blue block p-3 pt-2 pb-1 flex flex-row justify-left"><FontAwesomeIcon icon={faArrowLeft} /><span className="w-full ml-5">Terug</span></span></Link>
          </div>
          <div className="ml-10">{breadcrumb}</div>
        </div>
       
        <Container>
          <div className="flex flex-row">
            <div className="w-9/12">
              <ImageGallery images={ images !== null ? images : null} title={title} main={mainImg} origin={1}/>
              <WoningInfo data={data.wpBinnenkortInDeVerkoop} surface={woonoppervlak} />
              <WoningText data={data.wpBinnenkortInDeVerkoop} />
              {/* <WoningInfoAccordion data={data.wpAangekochteWoning} /> */}
            </div>
            <div className="w-3/12 ml-8">
              <WoningContact seller={seller} image={mainImg !== undefined ? mainImg : ''} scrollHandler={scrollHandler} contactpersoon={data.wpBinnenkortInDeVerkoop.binnenkortVerkoop.contactpersoon} vestiging={data.wpBinnenkortInDeVerkoop.categories?.nodes[0].name} origin={1}  />
            </div>
          </div>
        </Container>
        
      </Container>
      {/* <WoningBijlagen data={data.wpWoning} floorplan={floorPlans} documents={documents}></WoningBijlagen> */}
    </>
  )
}

export default SingleWoningBinnenkortTemplate;

export const pageQuery = graphql`
  query($id: Int!){
    wpBinnenkortInDeVerkoop(databaseId: { eq: $id }){
        categories {
            nodes {
              name
            }
          }
        uri
        title
        content
        binnenkortVerkoop {
          bouwjaar
          energieklasse
          inhoud
          kamers
          objectType
          perceeloppervlakte
          slaapkamers
          typeWoning
          woonoppervlakte
          plaats
          hoofdfoto {
            mediaItemUrl
            }
          contactpersoon
        }
        seo {
          breadcrumbs {
            text
            url
          }
        }
    }
    allWpVerkopendeMakelaar {
      nodes {
        title
        verkopendeMakelaars {
          fieldGroupName
          foto {
            mediaItemUrl
          }
          emailadres
          telefoonnummer
          vestiging
          volgnummer
        }
      }
    }
  }
`